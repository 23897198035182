<template>
    <div id="content">
        <shutter-panel ref="shutterPanel" />
    </div>
</template>

<script type="text/javascript">
import Shutter from "@/mixins/Shutter.js"
import ShutterInvoice from "@/mixins/shutters-manager/Invoice.js"

export default {
	name: 'Invoice',
	mixins: [Shutter, ShutterInvoice],
	data () {
		return {}
	},

	mounted() {
		this.init_component()
	},

	methods: {
		init_component() {
			this.setupInvoiceList()
		}
	}
};

</script>